const PATIENT_RESIDENCY = "Are you a resident of the United States or a U.S. Territory?";
const PATIENT_RESIDENCY_OTHER = "Are you a resident of the United States or the Commonwealth of Puerto Rico?";
const PATIENT_RESIDENCY_VEGZELMA = "Is the patient a resident of the United States or Puerto Rico?";
const PATIENT_RESIDENCY_PRESCRIBER = "Is the patient a resident of the United States or the Commonwealth of Puerto Rico?";

const INSURANCE_TYPE = "What type of prescription insurance coverage do you have?";
const INSURANCE_TYPE_OTHER = "What type of prescription insurance coverage does the patient have?";

const AGE_LABEL = "Is the patient 18 years of age or older?";
const AGE_LABEL_OTHER = "Is the patient, caregiver, or legal guardian of the patient 18 years of age or older?";
const AGE_LABEL_PATIENT = "Are you 18 years of age or older?";

const HIPPA_RELATIONSHIP_LABEL = "What is the relationship of the caregiver to the patient?";
const HIPPA_RELATIONSHIP_LABEL_OTHER = "What is your relationship to the patient?";

export default {
    PATIENT_RESIDENCY,
    PATIENT_RESIDENCY_OTHER,
    PATIENT_RESIDENCY_VEGZELMA,
    PATIENT_RESIDENCY_PRESCRIBER,
    INSURANCE_TYPE,
    INSURANCE_TYPE_OTHER,
    AGE_LABEL,
    AGE_LABEL_OTHER,
    AGE_LABEL_PATIENT,
    HIPPA_RELATIONSHIP_LABEL,
    HIPPA_RELATIONSHIP_LABEL_OTHER,
};
