import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import entryImage from '../../assets/img/yuflyma-entry.jpg';

const AdalimumabAATYEntry = () => {
    return (
        <section className='entry' style={{ backgroundImage: `url(${entryImage})` }}>
            <Container>
                <div className='entry-text'>
                    <Link to="/role-selection/adalimumab" className='theme-btn'>Enroll in Co-pay Program</Link>
                    <p className='text-1'>Commercially insured patients taking Adalimumab-aaty may be able to receive assistance through the Celltrion CARES<sup>&trade;</sup> Co-pay Assistance Program.</p>
                    <h5>If eligible, patients may pay as little as $0
                        for Adalimumab-aaty.
                    </h5>
                    <p className='text-2'>Celltrion CARES<sup>&trade;</sup> provides assistance to help with patient out-of-pocket costs
                        for Adalimumab-aaty.</p>
                    <p className='text-3'>In order to be eligible for Celltrion CARES<sup>&trade;</sup> Co-pay
                        Assistance, patients:
                    </p>
                    <ul>
                        <li>
                            Must have commercial insurance
                        </li>
                        <li>
                            Must not have Medicare, Medicaid,
                            or other government insurance

                        </li>
                        <li style={{ lineHeight: 1.5 }}>Must meet certain guidelines set
                            forth in the Terms and Conditions
                            for the Celltrion CARES<sup>&trade;</sup> Co-pay Assistance Program
                        </li>
                    </ul>
                    <p className='text-3'>
                    If you have any questions about the
                    Celltrion CARES<sup>&trade;</sup> Co-pay Assistance
                    Program for Adalimumab-aaty or
                    pharmacy assistance, please call
                    1.877.81CONNC (1.877.812.6662)<br></br>
                    Monday-Friday, 8 AM-8 PM ET.
                    </p>
                </div>
            </Container>
        </section>
    )
}

export default AdalimumabAATYEntry