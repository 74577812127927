import React, { useEffect, useRef } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import card from "../../assets/img/card.png";
import DownloadPDF from "../../utilities/pdfDownloader";
import cardyuflyma from "../../assets/img/card-yuflyma-small.svg";
import bevacizumab from "../../assets/img/Color_bevacizumab_adcdHigh.png";
import cardzymfentra from "../../assets/img/card-zymfentra.svg";
import cardadalimumab from "../../assets/img/card-adalimumab-small.svg";
import { useParams, useSearchParams } from "react-router-dom";
import yuflymaLogo from "../../assets/img/yuflyma-logo.png";
import zymfentraLogo from "../../assets/img/Zymfentra_brand.png";
import adalimumabLogo from "../../assets/img/Adalimumab-aaty_logo.png";

const Pharma = (props) => {
  const [searchParams] = useSearchParams();
  const { category } = useParams();
  const isFirstRender = useRef(false);
  const isZymfentra = category === "zymfentra";
  let cardID = localStorage.getItem("cardID");
  let rxBin = localStorage.getItem("rxBin");
  let rxGroup = localStorage.getItem("rxGroup");
  let rxPcn = localStorage.getItem("rxPcn");

  useEffect(async () => {
    if (isFirstRender.current) {
      const downloadFile = searchParams.get("downloadPDF");
      if (downloadFile === "true") {
        await DownloadPDF("root", "Instructions.pdf");
        setTimeout(() => {
          window.close();
        }, 500);
      }

      // setTimeout(() => {
      //     window.close();
      // }, 5000);
    }
    isFirstRender.current = true;
  }, []);

  let spanClass = (isZymfentra || category==="adalimumab")
    ? "rselection roles-section3"
    : "rselection roles-section2";

  return (
    <>
      <section className={spanClass}>
        {category === "yuflyma" ? (
          <img src={yuflymaLogo} className="bevacizumab" />
        ) : category === "vegzelma" ? (
          <img src={bevacizumab} className="bevacizumab" />
        ) : category === "zymfentra" ? (
          <img src={zymfentraLogo} className="bevacizumab" />
        ) : category === "adalimumab" ? (
            <img src={adalimumabLogo} className="bevacizumab" />
          ) : null}
        <Container>
          <Row>
            <Col>
              <div className="rselection-wrap role-wrap firstCard firstCardDownload">
                <div className="heads-icon">
                  <div className="all-set">
                    <p className="mainText mt1">You’re all set!</p>
                    <p className="mainText mt2">
                      Congratulations! Your patient is now enrolled in the
                      Celltrion CARES<sup>&trade;</sup> Co-pay Assistance
                      Program!
                    </p>
                  </div>
                </div>
                <div className="text-center">
                  <p className="text-center title-saving mainText">
                    Co-pay Savings Card Information
                  </p>
                  <div className="card-wrapper d-block">
                    {category === "yuflyma" ? (
                      <img src={cardyuflyma} alt="card" />
                    ) : category === "vegzelma" ? (
                      <img src={card} alt="card" />
                    ) : category === "zymfentra" ? (
                      <img src={cardzymfentra} alt="card" />
                    ) : category === "adalimumab" ? (
                      <img src={cardadalimumab} alt="card" />
                    ) : null}
                  <span style={{left:"37%"}}
                    className={
                      category === "yuflyma"
                        ? "spanYuflyma"
                        : category === "zymfentra"
                        ? "spanZymfentra"
                        : category === "adalimumab"
                        ? "spanAdalimumab"
                        : category === "vegzelma"
                        ? "spanVegzelma"
                        : ""
                    }
                  >
                      {cardID}
                    </span>
                    {category === "zymfentra" && (
                      <>
                        <span className="binZymfentra">{rxBin}</span>
                        <span className="pcnZymfentra">{rxPcn}</span>
                        <span className="groupZymfentra">{rxGroup}</span>
                      </>
                    )}
                  </div>
                  <div className="button-holders">
                    <Button
                      className="theme-button theme-button-text secondary-color font-14 fw-medium"
                      type="submit"
                    >
                      INSTRUCTIONS FOR USE
                    </Button>
                  </div>
                </div>

                <div className="font-14">
                  <p className="font-16 fw-medium">
                    PHARMACY CLAIMS PROCESSING:
                  </p>
                  <p>
                    When you use this card, you are certifying that you have not
                    submitted and will not submit a claim for reimbursement
                    under any federal, state or other governmental programs for
                    this prescription.
                  </p>
                  <ul>
                    {category === "zymfentra" && (
                      <>
                        <li>
                          Submit transaction to Paysign using{" "}
                          <strong>BIN #610020</strong>
                        </li>
                        <li>
                          If primary commercial prescription insurance exists,
                          input card information as secondary coverage and
                          transmit using the COB segment of the NCPDP
                          transaction. Applicable discounts will be displayed in
                          the transaction response.
                        </li>
                        <li>
                          Acceptance of this card and your submission of claims
                          for the Co-pay Program are subject to the Celltrion
                          CARES
                          <sup>&trade;</sup> Co-pay Assistance Program Terms and
                          Conditions posted at{" "}
                          <a
                            href="https://www.celltrionconnect.com/terms-of-use/"
                            target="_blank" rel="noreferrer"
                          >
                            celltrionconnect.com/terms-of-use/
                          </a>
                        </li>
                      </>
                    )}
                    {category !== "zymfentra" && (
                      <>
                        <li>
                        Submit transaction to BIN #610524
                        </li>
                        <li>
                          If primary commercial prescription insurance exists,
                          input card information as secondary coverage and
                          transmit using the COB segment of the NCPDP
                          transaction. Applicable discounts will be displayed in
                          the transaction response.
                        </li>
                        <li>
                          Acceptance of this card and your submission of claims
                          for the Co-pay Program are subject to the Celltrion
                          CARES<sup>&trade;</sup> Co-pay Assistance Program
                          Terms and Conditions posted at <a href="https://www.mckesson.com/mprstnc" target="_blank" rel="noreferrer">www.mckesson.com/mprstnc</a>
                        </li>
                      </>
                    )}
                    <li>
                    The Patient is not eligible if prescriptions are paid in part or
                    full by any state or federally funded programs, including
                    but not limited to Medicare or Medicaid, Medigap, VA, DOD,
                    TriCare, or where prohibited by law.
                    </li>
                    <li>
                    The Celltrion CARES<sup>&trade;</sup> Co-pay card is not valid for use with
                    any other prescription drug discount or cash cards. Claims
                    submitted utilizing the program are subject to audit or
                    validation.
                    </li>
                  </ul>

                  <p>
                    For questions regarding setup, claim transmission, patient
                    eligibility or other issues, call the Celltrion CARES
                    <sup>&trade;</sup> Co-pay Program at{" "}
                    <span className="fw-medium">
                      1-877-81CONNC (1-877-812-6662)
                    </span>
                    , 8 AM-8 PM ET, Monday - Friday.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          {/* </div> */}
        </Container>
      </section>
    </>
  );
};

export default Pharma;
