import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function TermsOfUse(props) {
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='popup'
            >
                <Modal.Body className='popup-padding'>

                <div className='heading txt-algn'>TERMS OF USE</div>
                <div className='txt-algn mr-15'>Updated as of February 23, 2023</div>
                <div className='txt-just'>Welcome. These Terms of Use (“Terms of Use”) apply to this website (“Website”), our portals, our SMS text notification services, and any other services provided by Celltrion USA, Inc. and/or any of its affiliates (“we”, “us”, or “our”) (collectively, our “Services”). The information provided through our Services is for general informational and educational purposes only. <span className='fw-600'>Please read and review the Terms of Use carefully before accessing or using our Services. Additionally, please read, agree, and accept the terms of our Privacy Policy [link to Privacy Policy]. By accessing or using our Services, you acknowledge that you have read, understood, and agreed to these Terms of Use. If you do not agree to these Terms of Use, you may not access or use our Services.</span></div>
                   <div className='txt-just'>
                    <div className='mt10'> 1. <div className='custli'><span className='fw-600'> Use of Our Services.</span>The information provided through our Services is for general informational and educational purposes. Certain Services are intended for particular audiences including Celltrion USA’s employees, customers and shareholders, as well as members of the health care community and the general public. Your access to and use of the information contained in our Services is subject to this Terms of Use Agreement. By accessing and using our Services, you accept, without limitation or qualification, this Terms of Use Agreement.</div></div>
                    <div className='mt10'>2. <div className='custli'><span className='fw-600'>User Conduct. By using the Services</span> including any content and services available through it, you agree that you shall not:</div></div>
                        <div className='mt10 pl-60'>a.<div className='custli'>delete, modify, hack, or attempt to change or alter any of the Services;</div></div> 
                        <div className='mt10 pl-60'>b.<div className='custli'>attempt to access accounts, computer systems, or networks connected to any of our servers or to the Services, through hacking, password mining, or any other means, or obtain or attempt to obtain any materials or information through any means not intentionally or readily made available through the Services;</div> </div>
                        <div className='mt10 pl-60'>c.<div className='custli'>use any device, software, or routine intended to damage or otherwise interfere with the proper functioning of the Services or servers or networks connected to the Services, or take any other action that interferes with administration, security, and/or operation of the Services or other parties’ use of the Services;</div></div>
                        <div className='mt10 pl-60'>d.<div className='custli'>use any robot, spider, or other automatic or manual device or process for the purpose of harvesting or compiling information on the Services for purposes other than for a generally available search engine;</div></div>
                        <div className='mt10 pl-60'>e.<div className='custli'>use any of our names, service marks, logos, or trademarks without prior written consent, including without limitation as metatags, search engine keywords, or hidden text;</div></div>
                        <div className='mt10 pl-60'>f.<div className='custli'>use any material or information, including images or photographs, which are made available through the Services in any manner that infringes any copyright, trademark, patent, trade secret, or other proprietary right of any party;</div></div>
                        <div className='mt10 pl-60'>g.<div className='custli'>transmit or upload files that contain viruses, Trojan horses, worms, time bombs, corrupted files, or any other similar software or programs that may damage the operation of another’s computer or property of another;</div></div>
                        <div className='mt10 pl-60'>h.<div className='custli'>create a false identity for the purpose of misleading others, breach any contractual or confidentiality obligations, or violate the privacy rights of others;</div></div>
                        <div className='mt10 pl-60'>i.<div className='custli'>defame, abuse, harass, stalk, threaten, or otherwise violate the legal rights of any third party; or </div></div>
                        <div className='mt10 pl-60'>j.<div className='custli'>provide false information, impersonate another person or entity, or misrepresent your affiliation with any entity.</div></div>
                    <div className='pl-30 mt10'>You are prohibited from using our Services to post or transmit any unlawful, threatening, libelous, defamatory, obscene, pornographic, illegal, or any material that could constitute or encourage conduct that would be considered a criminal offence, violate the rights of any party or which may otherwise give rise to civil liability or violate any law. You are also prohibited from using our Services to advertise or perform any commercial solicitation. We will have no obligations with respect to such communications. We reserve the right to remove any and all user submissions it deems in violation of these Terms.</div>
                    <div className='mt10'> 3. <div className='custli'><span className='fw-600'>Content. </span> Celltrion USA will use reasonable efforts to include accurate and up-to-date information in our Services but makes no warranties or representations of any kind as to its accuracy, currency or completeness. You agree that access to and use of our Services and the information therein is at your own risk. Celltrion USA disclaims all warranties, express or implied, including warranties of merchantability or fitness for a particular purpose. Neither Celltrion USA nor any party involved in creating, producing, or delivering our Services shall be liable for any damages, including without limitation, direct, incidental, consequential, indirect or punitive damages, arising out of access to, use of or inability to use our Services, or any errors or omissions in the content thereof. This limitation includes damages to, or for any viruses that infect, your computer equipment. You understand and acknowledge that your sole and exclusive remedy with respect to any defect or dissatisfaction with our services is to cease use of our services.</div></div>
                    <div className='mt10'> 4. <div className='custli'><span className='fw-600'>Indemnification.</span>You agree to indemnify, defend and hold harmless Celltrion USA, its officers, directors, employees, agents, suppliers and third party partners from and against all losses, expenses, damages and costs, including reasonable attorneys’ fees, resulting from any violation by you of these Terms of Use.</div></div>
                    <div className='mt10'> 5. <div className='custli'><span className='fw-600'>Privacy</span> Celltrion USA respects the privacy of users of our Services. Please refer to Celltrion USA’s Privacy Policy [link to Privacy Policy] that explains users’ rights and responsibilities with respect to information that is disclosed through our Services.</div></div>
                    <div className='mt10'> 6. <div className='custli'><span className='fw-600'>Third Party Websites and Links. </span>Our Services may contain references or links to other websites maintained by third parties over whom Celltrion USA has no control. Such links are provided merely as a convenience. Similarly, our Services may be accessed from third-party links over whom Celltrion USA has no control. Celltrion USA makes no warranties or representations of any kind as to the accuracy, currency, or completeness of any information contained in such third-party websites, including any third-party social media or mobile app platform. Inclusion of any third-party link on this Website does not imply an endorsement or recommendation by Celltrion USA and a link to this Website from another website does not imply a relationship between Celltrion USA and any third party. Your use of any such third-party site or platform is at your own risk and will be governed by such third party’s terms and policies (including its privacy policy).</div></div>
                    <div className='mt10'> 7. <div className='custli'><span className='fw-600'>Text Message Terms.</span> By providing your telephone number to us or your physician and/or agreeing to participate in a text or communication program (collectively, the “Program”), you consent to receive text messages and/or push notifications from or on behalf of us and/or our partners and suppliers, including messages using automated dialing technology. Such messages may be service-related or contain information regarding products or services that may be of interest to you, service announcements, or programs or other topics of interest and may include confirmation or reminders for billing purposes, medical appointments at our facilities, etc. You do not need to provide this consent in order to purchase any products or services. However, you acknowledge that opting out of receiving text (SMS) messages may impact your experience with the service(s) that rely on communications via text (SMS) messaging.
                        <div className='pl-30 mt10'>a.<div className='custli'> <span className='textDeco'> Unsecure </span> - Messages are unencrypted. You understand that text messages have inherent privacy risks, including that unencrypted text messages are not secure and could be accessed by an unauthorized party, intercepted, or altered without your knowledge or authorization.</div></div>
                        <div className='pl-30 mt10'>b.<div className='custli'> <span className='textDeco'> Opt Out </span> - To stop receiving text messages, if indicated reply STOP or contact us directly. You consent to receive one last message from us confirming your inactivation.</div></div>
                        <div className='pl-30 mt10'>c.<div className='custli'> <span className='textDeco'> Usage  </span> 
                            <div className='pl-30 mt10'>i.<div className='custli'> Frequency of text messages and notifications will vary depending upon the Services you receive or your transactions with us.</div></div>
                            <div className='pl-30 mt10'>ii.<div className='custli'> You represent that you are the account holder for the mobile telephone number(s) that you provide to opt in to the Program. You are responsible for notifying us immediately if you change your mobile telephone number. You may notify us of a number change.</div></div>
                            <div className='pl-30 mt10'>iii.<div className='custli'> As a user of this Program, you acknowledge that text messages are distributed via third-party mobile network providers, and therefore, we are unable to control all functions related to the delivery of text messages. You acknowledge that it may not be possible to transmit all text messages successfully. We will not be liable for any delays in the receipt of any SMS messages, nor will we be liable for any undelivered messages, as delivery is subject to effective transmission from your network operator.</div></div>
                            <div className='pl-30 mt10'>iv.<div className='custli'> The Program may not be available on all U.S. mobile carriers. Note that your carrier is not liable for delayed or undelivered messages.</div></div>
                            <div className='pl-30 mt10'>v.<div className='custli'> While we do not charge you for these services, message and data rates may apply to each text message sent or received in connection with the Program, as provided in your mobile telephone service rate plan (please contact your mobile telephone carrier for details about available plans). Applicable roaming charges may apply.</div></div>
                            <div className='pl-30 mt10'>vi.<div className='custli'> Data obtained from you in connection with this Program may include your telephone number, your carrier’s name, and details of the message (date, time, and content). We may use this information to contact you in accordance with these Terms and to provide the services you request. For additional information on our data collection and use, please read our Privacy Policy [link to Privacy Policy].</div></div>
                        
                                 
            
                        </div></div>
                        <div className='pl-30 mt10'>d.<div className='custli'> <span className='textDeco'> Indemnification  </span> - You agree to indemnify us and any third parties texting on its behalf in full for all claims, expenses, and damages related to or caused, in whole or in part, by your failure to immediately notify us if you change your telephone number, including but not limited to all claims, expenses, and damages related to or arising under the Telephone Consumer Protection Act. </div></div>
                        <div className='pl-30 mt10'>e.<div className='custli'> <span className='textDeco'> Suspension; Termination  </span> - We may immediately suspend or terminate your participation in the Program if it believes you are in breach of these Terms. Your participation in this Program is also subject to termination in the event that your mobile telephone service terminates or lapses. We reserve the right to modify or discontinue, temporarily or permanently, all or any part of the Program, with or without notice to you. </div></div>
                        <div className='pl-30 mt10'>f.<div className='custli'> <span className='textDeco'> Modification  </span> - We may revise, modify, or amend these Terms at any time. Any such revision, modification, or amendment shall take effect when it is posted to the Website. You agree to review these Terms periodically to ensure that you are aware of any changes. Your continued participation in this Program, including receipt of text messages and/or push notifications without opting out will indicate your acceptance of those changes. </div></div>
                    </div></div>
                    <div className='mt10'> 8. <div className='custli'><span className='fw-600'>Medical Information. </span>The information contained in or provided by our Services may relate to various medical conditions and their treatment. Such information is provided for informational purposes only and is not meant to be a substitute for advice provided by a doctor or other qualified health care professional. Patients should not use the information contained herein for diagnosing or treating a health condition or disease. Patients should always consult with a doctor or other health care professional for medical advice or information about diagnosis and treatment. There is no physician-patient relationship arising solely by virtue of using the Services. </div></div>
                    <div className='mt10'> 9. <div className='custli'><span className='fw-600'>Minimum Age Requirement. </span> Our Services are not intended for users under the age of 13. We do not knowingly collect information from users under the age of 13. Users under the age of 13 are expressly prohibited from submitting their information to us via the Services or using portions of the Website or Services that require registration.</div></div>
                    <div className='mt10'> 10. <div className='custli'><span className='fw-600'>Forward Looking Statements. </span>Our Services contain forward-looking statements about Celltrion USA’s financial and operating performance, business plans and prospects, in-line products and products in development that involve substantial risks and uncertainties. Actual results could differ materially from the expectations and projections set forth in those statements. Such risks and uncertainties include, among other things, the uncertainties inherent in pharmaceutical research and development; decisions by regulatory authorities regarding whether and when to approve drug applications and supplemental drug applications as well as their decisions regarding labeling and other matters that could affect the availability or commercial potential of Celltrion USA products; competitive developments; the ability to successfully market both new and existing products; challenges to the validity and enforcement of Celltrion USA’s patents; trends toward managed care and health care cost containment; governmental laws and regulations affecting health care, including without limitation regarding pharmaceutical product access, pricing and reimbursement; and general economic conditions, such as interest rate and foreign currency exchange rate fluctuations. Celltrion USA assumes no obligation to update any forward-looking statements as a result of new information or future events or developments.</div></div>
                    <div className='mt10'> 11. <div className='custli'><span className='fw-600'>Non-Confidential Information. </span>Subject to any applicable terms and conditions set forth in our Privacy Policy, any communication or other material that you send to us through our Services, the Internet, electronic mail, or otherwise, such as any questions, comments, suggestions or the like, is and will be deemed to be non-confidential and Celltrion USA shall have no obligation of any kind with respect to such information. Celltrion USA shall be free to use any ideas, concepts, know-how or techniques contained in such communication for any purpose whatsoever, including but not limited to, developing, manufacturing, and marketing products.</div></div>
                    <div className='mt10'> 12. <div className='custli'><span className='fw-600'>Trademarks.</span>All product names, whether or not appearing in large print or with the trademark symbol, are trademarks of Celltrion USA, its affiliates, related companies or its licensors or joint venture partners, unless otherwise noted. The use or misuse of these trademarks or any other materials, except as permitted herein, is expressly prohibited and may be in violation of copyright law, trademark law, the law of slander and libel, the law of privacy and publicity, and communications regulations and statutes. Please be advised that Celltrion USA actively and aggressively enforces its intellectual property rights to the fullest extent of the law.</div></div>
                    <div className='mt10'> 13. <div className='custli'><span className='fw-600'>Copyrights. </span>The entire contents of our Website and Services are subject to copyright protection. [Copyright © 2002-2023 Celltrion USA, Inc.] The contents of our Website and Services may not be copied other than for noncommercial individual reference with all copyright or other proprietary notices retained, and thereafter may not be recopied, reproduced, or otherwise redistributed. Except as expressly provided above, you may not otherwise copy, display, download, distribute, modify, reproduce, republish, or retransmit any information, text or documents contained in our Website and Services or any portion thereof in any electronic medium or in hard copy, or create any derivative work based on such images, text, or documents, without the express written consent of Celltrion USA. Nothing contained herein shall be construed as conferring by implication, estoppel, or otherwise any license or right under any patent or trademark of Celltrion USA, or any third party.</div></div>
                    <div className='mt10'> 14. <div className='custli'><span className='fw-600'>Void Where Prohibited. </span>Our Services and their contents are intended to comply with the laws and regulations in the U.S. Although the information in our Services is accessible to users outside of the U.S., the information pertaining to Celltrion USA products is intended for use only by residents of the U.S. Other countries may have laws, regulatory requirements, and medical practices that differ from those in the U.S. This site links to other sites produced by Celltrion USA’s various operating divisions and subsidiaries, some of which are outside the U.S. Those sites may have information that is appropriate only to that particular originating country. Celltrion USA reserves the right to limit provision of its products or services to any person, geographic region, or jurisdiction and/or to limit the quantities or any products or services we provide. Any offer for any product or service made through our Services is void where prohibited.</div></div>
                    <div className='mt10'> 15. <div className='custli'><span className='fw-600'>Governing Laws. </span>This Terms of Use Agreement and your use of our Services shall be governed by the laws of the United States of America and the State of Delaware without regard to its conflicts of laws principles. Any legal action or proceeding related to our Services shall be brought exclusively in a federal or state court of competent jurisdiction sitting in Delaware or New Jersey.</div></div>
                    <div className='mt10'> 16. <div className='custli'><span className='fw-600'>Miscellaneous.</span>If any provision of this Agreement is held to be unlawful, void or unenforceable, then such provision shall be severable without affecting the enforceability of all remaining provisions. Celltrion USA reserves the right to alter or delete materials from our Services at any time at its discretion.</div></div>
                    <div className='mt10'> 17. <div className='custli'><span className='fw-600'>Contact Information.</span> If you have any questions about these Terms of Use, our practices or Services, or your use of the Website, please email us at ethicscompliance_usa@celltrionhc.com.</div></div>
</div>
                </Modal.Body>
                <Modal.Footer className='popup-footer'>
                    <Button className='theme-btn' onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>

    );
}
export default TermsOfUse