// React Imports
import React from "react";
import { Accordion, Container } from "react-bootstrap";
// Image Imports

import bevacizumab from "../assets/img/product-select-vegzelma.png";
import yuflymaLogo from "../assets/img/product-select-yuflyma.png";
import zymfentraLogo from "../assets/img/product-select-zymfentra.png";
import adalimumabLogo from "../assets/img/product-select-adalimumab.png";
import caretDown from "../assets/img/caret-down.png";

const styles = {
  container: {
    backgroundColor: "lightgray",
    padding: "20px",
  },
  title: {
    color: "red",
    fontSize: "24px",
  },
};

const Home = () => {
  return (
    <section className="home">
      <Container>
        <div className="home-text next">
          <h1>By your side, every step of the way</h1>
          <div className="home-text-wrap">
            <p>
              Please select which product you wish to Enroll in Financial
              Support
            </p>
            <div style={{ paddingTop: "50px" }}>
              <Accordion defaultActiveKey="1" style={{ width: "465px" }}>
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="custom-accordion-header">
                    <span
                      style={{
                        color: "#777777",
                        fontSize: "22px",
                        fontFamily: "Myriad Pro Bold",
                        lineHeight: "0",
                      }}
                    >
                      SELECT A PRODUCT
                    </span>
                    <img
                      src={caretDown}
                      alt="Custom Arrow"
                      className="custom-arrow"
                      style={{
                        marginLeft: "auto", // Align the arrow to the right
                        width: "24px", // Adjust the size as needed
                      }}
                    />
                  </Accordion.Header>
                  <Accordion.Body
                    style={{
                      backgroundColor: "white",
                      borderTop: "1px solid #777777",
                      padding: "30px 0px 30px 30px",
                      height: "130px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <a
                      href="/entry/zymfentra"
                      style={{ textDecoration: "none" }}
                    >
                      <img
                        src={zymfentraLogo}
                        alt="Zymfentra"
                        style={{ objectFit: "contain", maxWidth: "275px" }}
                      />
                    </a>
                  </Accordion.Body>
                  <Accordion.Body
                    style={{
                      backgroundColor: "white",
                      borderTop: "1px solid #777777",
                      padding: "15px 0px 30px 25px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <a href="/entry/yuflyma" style={{ textDecoration: "none" }}>
                      <img
                        src={yuflymaLogo}
                        alt="Yuflyma"
                        style={{ objectFit: "contain", maxWidth: "275px" }}
                      />
                    </a>
                  </Accordion.Body>
                  <Accordion.Body
                    style={{
                      backgroundColor: "white",
                      borderTop: "1px solid #777777",
                      padding: "40px 0px 30px 30px",
                      height: "130px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <a
                      href="/entry/adalimumab"
                      style={{ textDecoration: "none" }}
                    >
                      <img
                        src={adalimumabLogo}
                        alt="Adalimumab"
                        style={{ objectFit: "contain", maxWidth: "275px" }}
                      />
                    </a>
                  </Accordion.Body>
                  <Accordion.Body
                    style={{
                      backgroundColor: "white",
                      borderTop: "1px solid #777777",
                      padding: "30px 0px 30px 30px",
                      height: "130px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <a
                      href="/entry/vegzelma"
                      style={{ textDecoration: "none" }}
                    >
                      <img
                        src={bevacizumab}
                        alt="Vegzelma"
                        style={{ objectFit: "contain", maxWidth: "275px" }}
                      />
                    </a>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Home;
